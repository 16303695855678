import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardHeader, Grid } from '@mui/material';
import AppSimpleHorizontalWithNegativeBarChart from 'src/sections/@dashboard/app/AppSimpleHorizontalWithNegativeBarChart';
import { getOrganizedAnalytics } from 'src/services/analytics.service';
// import { getFileTypeAnalytics } from 'src/services/analytics.service';

class OrganizedAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            countAnalytics: {
                category: [],
                organized: [],
                unorganized: []
            },
            sizeAnalytics: {
                category: [],
                organized: [],
                unorganized: []
            }
        }
    }

    async componentDidMount() {

        let organized_analytics = await getOrganizedAnalytics();
        
        if (organized_analytics.error) {
            console.error(organized_analytics.error);
            return;
        }
        organized_analytics = organized_analytics.organized_analytics;

        let organized = organized_analytics.find((item) => item.organized == true);
        let unorganized = organized_analytics.find((item) => item.organized == false);
        let total = {
            count: (organized ? organized.count : 0) + (unorganized ? unorganized.count : 0),
            size: (organized ? organized.size : 0) + (unorganized ? unorganized.size : 0)
        }

        let countOrganizedPercentage = Math.round(((organized ? organized.count : 0) / total.count * 100) * 100) / 100;
        let countUnorganizedPercentage = Math.round (((unorganized ? unorganized.count : 0) / total.count * 100) * 100) / 100;

        let countAnalytics = {
            category: ["Count"],
            organized: [countOrganizedPercentage],
            unorganized: [-1 * countUnorganizedPercentage]
        }

        let sizeOrganizedPercentage = Math.round(((organized ? organized.size : 0) / total.size * 100) * 100) / 100;
        let sizeUnorganizedPercentage = Math.round(((unorganized ? unorganized.size : 0) / total.size * 100) * 100) / 100;

        let sizeAnalytics = {
            category: ["Size"],
            organized: [sizeOrganizedPercentage],
            unorganized: [-1 * sizeUnorganizedPercentage]
        }

        this.setState({
            countAnalytics: countAnalytics,
            sizeAnalytics: sizeAnalytics
        });
        

    }

    render() {

        return (
            <>

                {(this.state.countAnalytics.category.length || this.state.sizeAnalytics.category.length) ? (
                    <Card variant='outlined'>
                        <CardHeader title='Files Organized v/s Unorganized' />
                        <Grid container spacing={2}>
                            {(this.state.countAnalytics.category.length) ? (
                                <Grid item xs={12} md={6}>
                                    <AppSimpleHorizontalWithNegativeBarChart
                                        category={this.state.countAnalytics.category}
                                        organized={this.state.countAnalytics.organized}
                                        unorganized={this.state.countAnalytics.unorganized}
                                    />
                                </Grid>
                            ) : null}
                            {(this.state.sizeAnalytics.category.length) ? (
                                <Grid item xs={12} md={6}>
                                    <AppSimpleHorizontalWithNegativeBarChart
                                        category={this.state.sizeAnalytics.category}
                                        organized={this.state.sizeAnalytics.organized}
                                        unorganized={this.state.sizeAnalytics.unorganized}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </Card>
                ) : null}
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <OrganizedAnalytics {...props} navigate={navigate} params={params} />;
}
