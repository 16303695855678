import ReactApexChart from 'react-apexcharts';
import React from 'react';

// ----------------------------------------------------------------------

export default class AppSimpleHorizontalWithNegativeBarChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: 'Unorganized',
          data: this.props.unorganized ? this.props.unorganized : []
        },{
          name: 'Organized',
          data: this.props.organized ? this.props.organized : []
        }],
        options: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            }
          },
          colors: ['#FF4560', '#008FFB'],
          plotOptions: {
            bar: {
              borderRadius: 5,
              borderRadiusApplication: 'end', // 'around', 'end'
              borderRadiusWhenStacked: 'all', // 'all', 'last'
              horizontal: true,
              barHeight: '80%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            }
          },
          yaxis: {
            min: -100,
            max: 100,
            stepSize: 1
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val
              }
            },
            y: {
              formatter: function (val) {
                return Math.abs(val) + "%"
              }
            }
          },
          xaxis: {
            categories: this.props.category ? this.props.category : [],
            labels: {
              formatter: function (val) {
                return Math.abs(Math.round(val)) + "%"
              }
            }
          },
        },
      
      
      };
    }

  

    render() {
      return (
            <>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={150} />
            </>
      );
    }
  }