import { getToken } from "./auth.service";
import config from "src/config";
import CryptoJS from 'crypto-js';

const getRequestHeaders = () => {
    try {
        const token = getToken();
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (token) {
            headers.token = `${token}`;
        }
        return headers;
    } catch (error) {
        return null;
    }
}

const getQueryStringFromObject = (obj) => {
    let queryString = '';
    let queryStringArray = [];
    for (const [key, value] of Object.entries(obj)) {
        queryStringArray.push(`${key}=${value}`);
    }
    queryString = queryStringArray.join('&');
    return queryString;
}

const getRolesForSelection = (maxValue=null, minValue=0) => {
    let current_role_key = localStorage.getItem('role');

    if(!maxValue) {
        let temp = Object.keys(config.ROLES).find(role => config.ROLES[role].key === current_role_key);
        let current_role = config.ROLES[temp];
        maxValue = current_role.value;
    }

    let roles = [];
    for (const [key, value] of Object.entries(config.ROLES)) {
        if(value.value < maxValue && value.value >= minValue) {
            roles.push({
                id: value.key,
                label: value.name
            });
        }
    }
    return roles;
}

const downloadFileFromResponse = async (response, file_name, axios=false) => {
    let blob;
    if(axios) {
        blob = await response.data;
    } else {
        blob = await response.blob();
    }

    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blob);
}

const e2e_encrypt = (plainText, json=true) => {
    if (json) {
        plainText = JSON.stringify(plainText);
    }
    let encryptedPlainText = CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_E2E_KEY).toString();
    return encryptedPlainText;
}

const e2e_decrypt = (encryptedPlainText, json=true) => {
    let bytes  = CryptoJS.AES.decrypt(encryptedPlainText, process.env.REACT_APP_E2E_KEY);
    let decryptedPlainText = bytes.toString(CryptoJS.enc.Utf8);
    if (json) {
        decryptedPlainText = JSON.parse(decryptedPlainText);
    }
    return decryptedPlainText;
}

export {
    getRequestHeaders,
    getQueryStringFromObject,
    getRolesForSelection,
    downloadFileFromResponse,
    e2e_encrypt,
    e2e_decrypt
}
