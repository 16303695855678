import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Typography, Grid, Button, TextField, InputAdornment , IconButton, Stack, ThemeProvider } from '@mui/material';
import config from 'src/config';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, getToken, login, storeKey, storeToken } from 'src/services/auth.service';
import logo from 'src/assets/images/logo.png';
import { createTheme } from '@mui/material/styles'
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ff9933'
      }
    },
});

class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pristine: {
                username: true
            },

            // payload
            username: ''
        }

    }

    async componentDidMount() {
        
    }


    validateUsername(username, checkPristine = true) {

        if (checkPristine && this.state.pristine.username) {
            return true;
        }

        if (!username) {
            return false;
        }

        if (username.indexOf('@') === -1) {
            return false;
        }

        if (username.indexOf('.') === -1) {
            return false;
        }

        return true;
    }

    validateForm() {
        return this.validateUsername(this.state.username, false);
    }


    async handleForgotPassword() {

        showLoading(true);

        let payload = {
            username: this.state.username,
            frontend_base_url: window.location.origin
        };

        let forgotPasswordRes = await forgotPassword(payload);
        showLoading(false);

        if (forgotPasswordRes.error) {
            Swal.fire({
                title: 'Error',
                text: forgotPasswordRes.error,
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        Swal.fire({
            title: 'Success',
            text: 'Password reset link has been sent to your email address',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.navigate('/login');
            }
        });

        
    }

    render() {

        return (
            <>
                <Helmet>
                    <title> Forgot Password | {config.APP_NAME} </title>
                </Helmet>

                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
                    <Grid item xs={12} sm={8} md={5} lg={4}>


                            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                <Typography variant="h4">
                                    Reset Password for
                                </Typography>
                                <img src={logo} alt="logo" width={'100px'} height={'auto'} style={{ verticalAlign: 'middle' }} />
                            </Stack>

                            <ThemeProvider theme={theme}>

                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email address"
                                    sx={{ mb: 2 }}
                                    value={this.state.username}
                                    onChange={(e) => {
                                        this.setState({
                                            username: e.target.value,
                                            pristine: {
                                                ...this.state.pristine,
                                                username: false
                                            }
                                        });
                                    }}
                                    error={!this.validateUsername(this.state.username)}
                                    helperText={!this.validateUsername(this.state.username) ? 'Invalid email address' : ''}
                                />
                                
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={this.handleForgotPassword.bind(this)}
                                    disabled={!this.validateForm()}
                                    style={{ color: 'white' }}
                                >
                                    Reset Password
                                </Button>
                            </ThemeProvider>

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <ForgotPasswordPage {...props} navigate={navigate} />;
}

