import axios from './axios.service';
import config from './../config';
import { getRequestHeaders } from './common.service';

const getGoogleDriveStatus = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/googledrive/status', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const connectGoogleDrive = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/googledrive/connect', payload, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const syncGoogleDrive = async () => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/googledrive/sync', {}, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const unsyncGoogleDrive = async () => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/googledrive/unsync', {}, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const downloadFile = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/googledrive/download/' + fileId, {
                headers: getRequestHeaders(),
                responseType: 'blob'
            });
            return response;
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const viewFile = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/googledrive/view/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDownloadLink = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/googledrive/download_link/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getGoogleDriveStatus,
    connectGoogleDrive,
    syncGoogleDrive,
    unsyncGoogleDrive,
    downloadFile,
    viewFile,
    getDownloadLink
}