import { Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AllInboxIcon from '@mui/icons-material/AllInbox';

class ContainersView extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            page_size: 8,
            current_page: 0
        };
    }

    render() {

        if (!this.props.containers) {
            return null;
        }

        // group containers by 8
        let pages = [];
        let page = [];
        this.props.containers.forEach((container, idx) => {
            page.push(container);
            if (page.length === this.state.page_size || idx === this.props.containers.length - 1) {
                pages.push(page);
                page = [];
            }
        });

        if (!pages.length) {
            return null;
        }

        return (
            <Grid container spacing={2} style={{marginBottom: '20px'}}>

                {pages[this.state.current_page].map((container, idx) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={idx}>
                            <Card
                                sx={{width: '100%', cursor: 'pointer', borderRadius: '5px'}}
                                variant='outlined'
                                onClick={async () => {
                                    this.props.onContainerClick(container);
                                }}
                            >
                                <CardContent style={{paddingTop: 3, paddingBottom: 3, paddingLeft: 7, paddingRight: 7}}>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography variant="h6">
                                            {container}
                                        </Typography>
                                        <AllInboxIcon style={{color: 'gray'}} />
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6">
                            Containers {this.state.current_page * this.state.page_size + 1} - {Math.min((this.state.current_page + 1) * this.state.page_size, this.props.containers.length)} of {this.props.containers.length}
                        </Typography>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        current_page: this.state.current_page - 1
                                    });
                                }}
                                disabled={this.state.current_page === 0}
                                variant='outlined'
                                sx={{marginRight: 1}}
                            >
                                Previous
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        current_page: this.state.current_page + 1
                                    });
                                }}
                                disabled={this.state.current_page === pages.length - 1}
                                variant='outlined'
                                sx={{marginLeft: 1}}
                            >
                                Next
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider style={{width: '100%'}} />
                </Grid>


            </Grid>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <ContainersView {...props} navigate={navigate} params={params} />;
}
