import axios from 'axios'
import { e2e_decrypt, e2e_encrypt } from './common.service';
import app_config from '../config';

axios.interceptors.request.use((config) => {
    // encrypt the payload
    if(config?.data && !config?.data?.encrypted && app_config.APP_MODE != 'development') {
        config.data = { encrypted: e2e_encrypt(config.data) };
    }
    return config;
}, (error) => {
    if (error.config?.data && !error.config?.data?.encrypted && app_config.APP_MODE != 'development') {
        error.config.data = { encrypted: e2e_encrypt(error.config.data) };
    }
    return Promise.reject(error);
})

axios.interceptors.response.use((response) => {
    // decrypt the payload
    if(response?.data?.encrypted) {
        response.data = e2e_decrypt(response.data.encrypted);
    }
    return response;
}, (error) => {
    if (error.response?.data?.encrypted) {
        error.response.data = e2e_decrypt(error.response.data.encrypted);
    }
    return Promise.reject(error);
});

export default axios;
