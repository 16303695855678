import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Autocomplete, Box, Card, CardContent, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, Pagination, Stack, Tab, TextField, Typography } from '@mui/material';
import config from 'src/config';
import SearchIcon from '@mui/icons-material/Search';
import LabelIcon from '@mui/icons-material/Label';
import Swal from 'sweetalert2';
import showLoading from './../common/loading';
import { getFiles } from '../services/file.service';
import { useNavigate, useParams } from 'react-router-dom';
import FileCard from 'src/components/file-card';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactWebStore } from 'react-webstore';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ContainersView from 'src/components/containers-view';
import { getAvailableStorageServices } from 'src/services/storage.service';
import { fData } from 'src/utils/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import AddLabelOrFlagModal from 'src/modals/AddLabelOrFlag.modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StorageIcon from '@mui/icons-material/Storage';
import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Duplicates from 'src/components/declutter/duplicates';

const DocxImg = require('./../assets/fileTypeIcons/docx.png');
const PdfImg = require('./../assets/fileTypeIcons/pdf.png');
const XlsImg = require('./../assets/fileTypeIcons/xls.png');
const XlsxImg = require('./../assets/fileTypeIcons/xlsx.png');
const PptImg = require('./../assets/fileTypeIcons/ppt.png');
const PptxImg = require('./../assets/fileTypeIcons/pptx.png');
const PngImg = require('./../assets/fileTypeIcons/png.png');
const FileImg = require('./../assets/fileTypeIcons/file.png');


// ----------------------------------------------------------------------

const fileTypeIcon = {
    'docx': DocxImg,
    'pdf': PdfImg,
    'xls': XlsImg,
    'xlsx': XlsxImg,
    'ppt': PptImg,
    'pptx': PptxImg,
    'png': PngImg,
    'file': FileImg
}

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

class Declutter extends React.Component {
  

	constructor(props) {
		super(props);

        this.rws = new ReactWebStore();

		this.state = {

            available_storage_services: [],

            current_tab: 'duplicate',
            
            files: [],
            file_containers: [],
            search: '',
            limit: 12,
            skip: 0,
            total_files: 0,
            total_size: 0,
            flags: [],
            labels: [],
            containers: [],
            file_ids: [],
            flagged: false,
            labelled: false,
            unorganized: true,
            organized: false,
            recent: true,
            recent_type: 'last_week',
            service: [],
            extension: [],
            advanced_search: false,
            show_selection: false,
            selected: {
                files: [],
                total_size: 0
            },
            select_all: false,
            addLabelModal: false,
            addFlagModal: false
        };

        this.fetchFiles = debounce(this.fetchFiles, 300);
	}


	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

    async componentDidMount() {
        await this.fetchFiles();

        this.rws.subscribe('sync_files', async (e) => {
            if (this.rws.getStore(e.type) == 'success') {
                this.fetchFiles(false);
            }
        })

        let available_storage_services = await getAvailableStorageServices();
        if (!available_storage_services.error) {
            this.setState({
                available_storage_services: available_storage_services.services
            })
        }
    }

    getQuery(all_files = false) {
        const options = {
            limit: this.state.limit,
            skip: this.state.skip
        }

        if (this.state.search)
            options.search = this.state.search;

        if (this.state.flags && this.state.flags.length > 0)
            options.flags = this.state.flags;

        if (this.state.labels && this.state.labels.length > 0)
            options.labels = this.state.labels;

        if (this.state.containers && this.state.containers.length > 0)
            options.containers = this.state.containers;

        if (this.state.recent)
            options.recent = this.state.recent_type;

        if (this.state.flagged)
            options.flagged = 'true';
        
        if (this.state.labelled)
            options.labelled = 'true';
        
        if (this.state.unorganized)
            options.unorganized = 'true';

        if (this.state.organized)
            options.organized = 'true';

        if (this.state.service && this.state.service.length > 0)
            options.service = this.state.service.join(',');

        if (this.state.extension && this.state.extension.length > 0)
            options.extension = this.state.extension.join(',');

        if (this.state.file_ids && this.state.file_ids.length > 0)
            options.file_ids = this.state.file_ids.join(',');

        if (all_files) {
            delete options.limit;
            delete options.skip;
            delete options.file_ids;
        }

        return options;

    }

    async fetchFiles(showLoader = true) {
        try {

            if (showLoader)
                showLoading(true);


            const options = this.getQuery();

            const files = await getFiles(options);
            if (files.error) {

                showLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: files.error_code ? convertMessageCodeToMessage(files.error_code) : files.error,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: config.primaryColor
                });
                return;
            }
            
            this.setState({
                files: files.files,
                file_containers: files.containers,
                total_files: files.count,
                total_size: files.total_size
            });
            showLoading(false);

        } catch (error) {
            showLoading(false);
            console.error("Error fetching files:", error.message)
        }
    }

    handleSearch = async (e) => {
        await this.setStateAsync({
            skip:0,
            search: e.target.value
        })

        if (this.state.search.length > 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search && this.state.containers.length > 0) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && this.state.file_ids.length) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: true,
                recent_type: 'last_week',
                unorganized: true
            })
        }

        await this.fetchFiles(false);
    }

    handleFlagsSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleLabelsSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleContainersSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleFlagged = async (e) => {
        await this.setStateAsync({
            skip:0,
            flagged: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleLabelled = async (e) => {
        await this.setStateAsync({
            skip:0,
            labelled: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleUnorganized = async (e) => {
        let unorganized = e.target.checked;
        let organized = this.state.organized;
        if (unorganized) {
            organized = false;
        }

        await this.setStateAsync({
            skip:0,
            unorganized,
            organized
        })
        await this.fetchFiles(false);
    }

    handleOrganized = async (e) => {
        let organized = e.target.checked;
        let unorganized = this.state.unorganized;
        if (organized) {
            unorganized = false;
        }

        await this.setStateAsync({
            skip:0,
            organized,
            unorganized
        })
        await this.fetchFiles(false);
    }


    handleRecent = async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: e.target.checked,
            recent_type: (e.target.checked) ? 'last_week' : ''
        })
        await this.fetchFiles(false);
    }

    handleRecentType = (type) => async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: true,
            recent_type: type
        })
        await this.fetchFiles(false);
    }

    handleService = (service) => async (e) => {
        let services = this.state.service;
        if (services.includes(service)) {
            services = services.filter(s => s !== service);
        } else {
            services.push(service);
        }
        await this.setStateAsync({
            skip:0,
            service: services
        })
        await this.fetchFiles(false);
    }

    handleExtension = (extension_array) => async (e) => {
        let extensions = this.state.extension;
        
        for (let ext of extension_array) {
            if (extensions.includes(ext)) {
                extensions = extensions.filter(e => e !== ext);
            } else {
                extensions.push(ext);
            }
        }

        await this.setStateAsync({
            skip:0,
            extension: extensions
        })
        await this.fetchFiles(false);
    }

    handleClearSearchAndFilters = async (showLoader=true) => {
        await this.setStateAsync({
            skip:0,
            search: '',
            flags: [],
            labels: [],
            containers: [],
            recent: false,
            recent_type: '',
            flagged: false,
            labelled: false,
            organized: false,
            unorganized: false,
            service: [],
            extension: []
        })
        this.fetchFiles(showLoader);
    }

    toggleShowSelectedFiles = async () => {
        if (this.state.file_ids.length > 0) {
            await this.setStateAsync({
                file_ids: []
            })
        } else {
            await this.handleClearSearchAndFilters(false);
            await this.setStateAsync({
                file_ids: this.state.selected.files || []
            })
        }
        await this.fetchFiles();
    }

  render() {
	return (
	  <>
		<Helmet>
		  <title> Declutter | {config.APP_NAME} </title>
		</Helmet>

        <Grid container spacing={2} mb={1} sx={{p: 0, justifyContent: 'space-between'}}>
		  <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
			  <Typography variant="h4">
				 Declutter
			  </Typography>
              <Grid display={'flex'} flexDirection={'row'}>
                {(this.state.available_storage_services.includes('onedrive')) && (
                    <OnedriveStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_ONEDRIVE_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('dropbox')) && (
                    <DropboxStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_DROPBOX_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('googledrive')) && (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
                        <GoogleDriveStatusCheckAndConnect
                            hideUnsyncButton={true}
                            minimal={true}
                            callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_DASHBOARD_CALLBACK_URL}
                        />
                    </GoogleOAuthProvider>
                )}
              </Grid>
		  </Grid>
		</Grid>

        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={this.state.current_tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => {
                        this.setState({current_tab: value})
                    }}>
                        <Tab label="Duplicates" value="duplicate" />
                    </TabList>
                </Box>
                <TabPanel value="duplicate">
                    <Duplicates />
                </TabPanel>
            </TabContext>
        </Box>

	  </>
	);
  }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Declutter {...props} navigate={navigate} params={params} />;
}
