import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import { connectDropbox, getDropboxStatus, syncDropbox, unsyncDropbox } from 'src/services/dropbox.service';
import Swal from 'sweetalert2';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';

// ----------------------------------------------------------------------

class StoragePage extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
        <Helmet>
          <title> Storage | {config.APP_NAME} </title>
        </Helmet>

        <Card sx={{mb: 1}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                        Dropbox
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DropboxStatusCheckAndConnect
                            callbackUrl={process.env.REACT_APP_DROPBOX_CALLBACK_URL}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card sx={{mb: 1}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                        Onedrive
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <OnedriveStatusCheckAndConnect
                            callbackUrl={process.env.REACT_APP_ONEDRIVE_CALLBACK_URL}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                        Google Drive
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleDriveStatusCheckAndConnect
                            callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
      </GoogleOAuthProvider>
      </>
    );
  }
}

export default function (props) {
    const navigate = useNavigate();

    return <StoragePage {...props} navigate={navigate} />;
}
