import numeral from 'numeral';
// import i18n from 'src/i18n';

// ----------------------------------------------------------------------

export function fNumber(number) {
  return numeral(number).format();
}

// export function fNumberBasedOnLocale(number) {

//   if (number === undefined || number === null) {
//     return '';
//   }

//   let currencyLocale = i18n.t('OPTIONS.CURRENCY_LOCALE');
//   return number.toLocaleString(currencyLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
// }

// export function fCurrencyBasedOnLocale(number) {
//   if (number === undefined || number === null) {
//     return '';
//   }

//   let currencyLocale = i18n.t('OPTIONS.CURRENCY_LOCALE');
//   let currencyCode = i18n.t('OPTIONS.CURRENCY_CODE');
//   return number.toLocaleString(currencyLocale, { style: 'currency', currency: currencyCode, minimumFractionDigits: 2, maximumFractionDigits: 2 });
// }

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
