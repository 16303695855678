import React from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { useNavigate } from 'react-router-dom';
import { getKey, logout, removeKey, removeToken } from 'src/services/auth.service';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

class AccountPopover extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      open: null,
      username: ''
    }
  }

  componentDidMount() {
    let username = getKey('username');
    this.setState({
      username: username
    });
  }

  handleOpen = (event) => {
    this.setState({
      open: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      open: null
    });
  };

  handleLogout = async () => {

    try {
      await logout();
    } catch (error) { }

    this.handleClose();
    removeToken();
    removeKey('username');
    removeKey('role');
    this.props.navigate('/login');
  }

  render() {


    return (
      <>
        <IconButton
          onClick={this.handleOpen}
          sx={{
            p: 0,
            ...(this.state.open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <AccountBoxIcon alt="photoURL" />
        </IconButton>
  
        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            {/* <Typography variant="subtitle2" noWrap>
              {account.displayName}
            </Typography> */}
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {this.state.username}
            </Typography>
          </Box>
  
          <Divider sx={{ borderStyle: 'dashed' }} />
  
          {/* <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem key={option.label} onClick={this.handleClose}>
                {option.label}
              </MenuItem>
            ))}
          </Stack>
  
          <Divider sx={{ borderStyle: 'dashed' }} /> */}
  
          <MenuItem onClick={this.handleLogout} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Popover>
      </>
    );


  }

}

export default function (props) {
  const navigate = useNavigate();

  return <AccountPopover {...props} navigate={navigate} />;
}