import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Autocomplete, Card, CardContent, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, Pagination, Stack, TextField, Typography } from '@mui/material';
import config from 'src/config';
import SearchIcon from '@mui/icons-material/Search';
import LabelIcon from '@mui/icons-material/Label';
import Swal from 'sweetalert2';
import showLoading from './../common/loading';
import { getFiles, getFilesContainers } from '../services/file.service';
import { useNavigate, useParams } from 'react-router-dom';
import FileCard from 'src/components/file-card';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactWebStore } from 'react-webstore';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ContainersView from 'src/components/containers-view';
import { getAvailableStorageServices } from 'src/services/storage.service';
import { fData } from 'src/utils/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import AddLabelOrFlagModal from 'src/modals/AddLabelOrFlag.modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StorageIcon from '@mui/icons-material/Storage';
import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';

const DocxImg = require('./../assets/fileTypeIcons/docx.png');
const PdfImg = require('./../assets/fileTypeIcons/pdf.png');
const XlsImg = require('./../assets/fileTypeIcons/xls.png');
const XlsxImg = require('./../assets/fileTypeIcons/xlsx.png');
const PptImg = require('./../assets/fileTypeIcons/ppt.png');
const PptxImg = require('./../assets/fileTypeIcons/pptx.png');
const PngImg = require('./../assets/fileTypeIcons/png.png');
const FileImg = require('./../assets/fileTypeIcons/file.png');


// ----------------------------------------------------------------------

const fileTypeIcon = {
    'docx': DocxImg,
    'pdf': PdfImg,
    'xls': XlsImg,
    'xlsx': XlsxImg,
    'ppt': PptImg,
    'pptx': PptxImg,
    'png': PngImg,
    'file': FileImg
}

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

class Files extends React.Component {
  

	constructor(props) {
		super(props);

        this.rws = new ReactWebStore();

		this.state = {

            available_storage_services: [],
            
            files: [],
            file_containers: [],
            search: '',
            mini_loading: false,
            limit: 12,
            skip: 0,
            total_files: 0,
            total_size: 0,
            flags: [],
            labels: [],
            containers: [],
            file_ids: [],
            flagged: false,
            labelled: false,
            unorganized: true,
            organized: false,
            recent: true,
            recent_type: 'last_week',
            service: [],
            extension: [],
            advanced_search: false,
            show_selection: false,
            selected: {
                files: [],
                total_size: 0
            },
            select_all: false,
            addLabelModal: false,
            addFlagModal: false
        };

        this.fetchFiles = debounce(this.fetchFiles, 100);
	}


	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

    async componentDidMount() {
        await this.fetchFiles();

        this.rws.subscribe('sync_files', async (e) => {
            if (this.rws.getStore(e.type) == 'success') {
                this.fetchFiles(false);
            }
        })

        let available_storage_services = await getAvailableStorageServices();
        if (!available_storage_services.error) {
            this.setState({
                available_storage_services: available_storage_services.services
            })
        }
    }

    getQuery(all_files = false) {
        const options = {
            limit: this.state.limit,
            skip: this.state.skip
        }

        if (this.state.search)
            options.search = this.state.search;

        if (this.state.flags && this.state.flags.length > 0)
            options.flags = this.state.flags;

        if (this.state.labels && this.state.labels.length > 0)
            options.labels = this.state.labels;

        if (this.state.containers && this.state.containers.length > 0)
            options.containers = this.state.containers;

        if (this.state.recent)
            options.recent = this.state.recent_type;

        if (this.state.flagged)
            options.flagged = 'true';
        
        if (this.state.labelled)
            options.labelled = 'true';
        
        if (this.state.unorganized)
            options.unorganized = 'true';

        if (this.state.organized)
            options.organized = 'true';

        if (this.state.service && this.state.service.length > 0)
            options.service = this.state.service.join(',');

        if (this.state.extension && this.state.extension.length > 0)
            options.extension = this.state.extension.join(',');

        if (this.state.file_ids && this.state.file_ids.length > 0)
            options.file_ids = this.state.file_ids.join(',');

        if (all_files) {
            delete options.limit;
            delete options.skip;
            delete options.file_ids;
        }

        return options;

    }

    async fetchFilesContainers(options) {
        try {

            const containers = await getFilesContainers(options);
            if (!containers) {
                return;
            }
            if (containers.error) {
                return;
            }

            this.setState({
                file_containers: containers.containers
            });
        } catch (error) {
            console.error("Error fetching files containers:", error.message)
        }
    }

    async fetchFiles(showLoader = true) {
        try {

            if (showLoader)
                showLoading(true);
            this.setState({
                mini_loading: true
            });


            const options = this.getQuery();

            this.fetchFilesContainers(options);
            const files = await getFiles(options);
            if (!files) {
                showLoading(false);
                this.setState({
                    mini_loading: false
                });
                return;
            }
            if (files.error) {

                showLoading(false);
                this.setState({
                    mini_loading: false
                });
                

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: files.error_code ? convertMessageCodeToMessage(files.error_code) : files.error,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: config.primaryColor
                });
                return;
            }
            
            this.setState({
                files: files.files,
                total_files: files.count,
                total_size: files.total_size,
                mini_loading: false
            });
            showLoading(false);

        } catch (error) {
            showLoading(false);
            console.error("Error fetching files:", error.message)
        }
    }

    handleSearch = async (e) => {
        await this.setStateAsync({
            skip:0,
            search: e.target.value
        })

        if (this.state.search.length > 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search && this.state.containers.length > 0) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && this.state.file_ids.length) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: true,
                recent_type: 'last_week',
                unorganized: true
            })
        }

        await this.fetchFiles(false);
    }

    handleFlagsSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleLabelsSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleContainersSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleFlagged = async (e) => {
        await this.setStateAsync({
            skip:0,
            flagged: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleLabelled = async (e) => {
        await this.setStateAsync({
            skip:0,
            labelled: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleUnorganized = async (e) => {
        let unorganized = e.target.checked;
        let organized = this.state.organized;
        if (unorganized) {
            organized = false;
        }

        await this.setStateAsync({
            skip:0,
            unorganized,
            organized
        })
        await this.fetchFiles(false);
    }

    handleOrganized = async (e) => {
        let organized = e.target.checked;
        let unorganized = this.state.unorganized;
        if (organized) {
            unorganized = false;
        }

        await this.setStateAsync({
            skip:0,
            organized,
            unorganized
        })
        await this.fetchFiles(false);
    }


    handleRecent = async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: e.target.checked,
            recent_type: (e.target.checked) ? 'last_week' : ''
        })
        await this.fetchFiles(false);
    }

    handleRecentType = (type) => async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: true,
            recent_type: type
        })
        await this.fetchFiles(false);
    }

    handleService = (service) => async (e) => {
        let services = this.state.service;
        if (services.includes(service)) {
            services = services.filter(s => s !== service);
        } else {
            services.push(service);
        }
        await this.setStateAsync({
            skip:0,
            service: services
        })
        await this.fetchFiles(false);
    }

    handleExtension = (extension_array) => async (e) => {
        let extensions = this.state.extension;
        
        for (let ext of extension_array) {
            if (extensions.includes(ext)) {
                extensions = extensions.filter(e => e !== ext);
            } else {
                extensions.push(ext);
            }
        }

        await this.setStateAsync({
            skip:0,
            extension: extensions
        })
        await this.fetchFiles(false);
    }

    handleClearSearchAndFilters = async (showLoader=true) => {
        await this.setStateAsync({
            skip:0,
            search: '',
            flags: [],
            labels: [],
            containers: [],
            recent: false,
            recent_type: '',
            flagged: false,
            labelled: false,
            organized: false,
            unorganized: false,
            service: [],
            extension: []
        })
        this.fetchFiles(showLoader);
    }

    toggleShowSelectedFiles = async () => {
        if (this.state.file_ids.length > 0) {
            await this.setStateAsync({
                file_ids: []
            })
        } else {
            await this.handleClearSearchAndFilters(false);
            await this.setStateAsync({
                file_ids: this.state.selected.files || []
            })
        }
        await this.fetchFiles();
    }

  render() {
    return (
	  <>
		<Helmet>
		  <title> Files | {config.APP_NAME} </title>
		</Helmet>

        <Grid container spacing={2} mb={1} sx={{p: 0, justifyContent: 'space-between'}}>
		  <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
			  <Typography variant="h4">
				 Files
			  </Typography>
              <Grid display={'flex'} flexDirection={'row'}>
                {(this.state.available_storage_services.includes('onedrive')) && (
                    <OnedriveStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_ONEDRIVE_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('dropbox')) && (
                    <DropboxStatusCheckAndConnect
                        hideUnsyncButton={true}
                        minimal={true}
                        callbackUrl={process.env.REACT_APP_DROPBOX_DASHBOARD_CALLBACK_URL}
                    />
                )}
                {(this.state.available_storage_services.includes('googledrive')) && (
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
                        <GoogleDriveStatusCheckAndConnect
                            hideUnsyncButton={true}
                            minimal={true}
                            callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_DASHBOARD_CALLBACK_URL}
                        />
                    </GoogleOAuthProvider>
                )}
              </Grid>
		  </Grid>
		</Grid>

        
        <Card variant='outlined' style={{marginBottom: 2}}>
            <CardContent style={{padding: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <TextField
                                size='small'
                                label={'Search'}
                                variant="outlined"
                                fullWidth
                                value={this.state.search}
                                InputProps={this.state.mini_loading ? {
                                    endAdornment: <CircularProgress size={20} />
                                } : {
                                    endAdornment: <SearchIcon />
                                }}
                                onChange={this.handleSearch}
                            />
                            <IconButton aria-label="clear" size="large"
                                onClick={async () => {
                                    await this.handleClearSearchAndFilters();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                            <IconButton aria-label="advanced-search" size="large"
                                onClick={async () => {
                                    await this.setStateAsync({
                                        advanced_search: !this.state.advanced_search
                                    })
                                }}
                            >
                                {(this.state.advanced_search) ? <ExpandLessIcon /> :  <ExpandMoreIcon />}
                            </IconButton>
                        </Stack>
                    </Grid>

                    {(!this.state.advanced_search) && (
                        <>
                            {(this.state.containers.length > 0) && (
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography variant="h6" gutterBottom>Containers:</Typography>
                                        {this.state.containers.map((container, index) => {
                                            return (
                                                <Chip
                                                    key={"state.containers." + index}
                                                    label={container}
                                                    variant='outlined'
                                                    onDelete={async () => {
                                                        let containers = this.state.containers;
                                                        containers = containers.filter(c => c !== container);
                                                        await this.setStateAsync({
                                                            skip:0,
                                                            containers
                                                        });
                                                        await this.fetchFiles();
                                                    }}
                                                    sx={{borderRadius: 1}}
                                                />
                                            )
                                        })}
                                    </Stack>
                                </Grid>
                            )}
                        </>
                    )}


                    {(this.state.advanced_search) && (
                        <>

                            <Grid item xs={12} md={6} lg={6}>
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    id="labels-outlined"
                                    options={[]}
                                    value={this.state.labels}
                                    onChange={async (event, newValue) => {
                                        await this.setStateAsync({
                                            labels: newValue
                                        });
                                        this.handleLabelsSearch();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Labels"
                                            placeholder="What is it ?"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                        <LabelIcon />
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                    fullWidth
                                    size='small'
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <Autocomplete
                                    freeSolo
                                    multiple
                                    id="containers-outlined"
                                    options={[]}
                                    value={this.state.containers}
                                    onChange={async (event, newValue) => {
                                        await this.setStateAsync({
                                            containers: newValue
                                        });
                                        this.handleContainersSearch();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Containers"
                                            placeholder="Which project ?"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                        <AllInboxIcon />
                                                    </React.Fragment>
                                                )
                                            }}
                                        />
                                    )}
                                    fullWidth
                                    size='small'
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}}>
                                    <Stack direction="row" spacing={2}>
                                        <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                                            <FlagOutlinedIcon style={{fontSize: '30px'}} />
                                        </Grid>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}} >
                                            {flags.map((flag, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={"flags."+index}
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.flags.includes(flag.value)}
                                                                sx={{
                                                                    color: flag.color,
                                                                    '&.Mui-checked': {
                                                                        color: flag.color,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        onChange={async (e) => {
                                                            if (e.target.checked) {
                                                                await this.setStateAsync({
                                                                    flags: [...this.state.flags, flag.value]
                                                                });
                                                                this.handleFlagsSearch();
                                                            } else {
                                                                await this.setStateAsync({
                                                                    flags: this.state.flags.filter(f => f !== flag.value)
                                                                });
                                                                this.handleFlagsSearch();
                                                            }
                                                        }}
                                                        label={flag.label}
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}} elevation={0}>
                                    <Stack direction="row" spacing={2}>
                                        <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                                            <FilterAltOutlinedIcon style={{fontSize: '30px'}} />
                                        </Grid>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Flagged"
                                                checked={this.state.flagged}
                                                onChange={this.handleFlagged}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Labelled"
                                                checked={this.state.labelled}
                                                onChange={this.handleLabelled}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Organized"
                                                checked={this.state.organized}
                                                onChange={this.handleOrganized}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Unorganized"
                                                checked={this.state.unorganized}
                                                onChange={this.handleUnorganized}
                                            />
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileTypeIcon.pdf} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('pdf')}
                                                onChange={this.handleExtension(['pdf'])}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileTypeIcon.docx} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('docx')}
                                                onChange={this.handleExtension(['docx', 'doc', 'txt', 'rtf', 'odt', 'md', 'pages'])}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileTypeIcon.xlsx} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('xlsx')}
                                                onChange={this.handleExtension(['xlsx', 'xls', 'csv', 'numbers'])}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileTypeIcon.pptx} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('pptx')}
                                                onChange={this.handleExtension(['pptx', 'ppt', 'keynote'])}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileTypeIcon.png} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('png')}
                                                onChange={this.handleExtension(['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'svg', 'webp'])}
                                            />
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Recent"
                                                checked={this.state.recent}
                                                onChange={this.handleRecent}
                                            />
                                            {(this.state.recent) && (
                                                <>
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 day"
                                                        checked={this.state.recent_type == 'last_day'}
                                                        onChange={this.handleRecentType('last_day')}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 week"
                                                        checked={this.state.recent_type == 'last_week'}
                                                        onChange={this.handleRecentType('last_week')}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 month"
                                                        checked={this.state.recent_type == 'last_month'}
                                                        onChange={this.handleRecentType('last_month')}
                                                    />
                                                </>
                                            )}
                                        </FormGroup>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}} elevation={0}>
                                    <Stack direction="row" spacing={2}>
                                        <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                                            <StorageIcon style={{fontSize: '30px'}} />
                                        </Grid>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={<img src={OnedriveLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.ONEDRIVE)}
                                                onChange={this.handleService(config.FILE_SERVICES.ONEDRIVE)}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={DropboxLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.DROPBOX)}
                                                onChange={this.handleService(config.FILE_SERVICES.DROPBOX)}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={GoogleDriveLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.GOOGLEDRIVE)}
                                                onChange={this.handleService(config.FILE_SERVICES.GOOGLEDRIVE)}
                                            />
                                        </FormGroup>
                                        
                                    </Stack>
                                </Card>
                            </Grid>
                        </>
                    )}
                </Grid>
            
            </CardContent>
        </Card>

        <Grid container>
            <Grid container>
                <Grid item xs={12} sx={{minHeight: '80vh', overflow: 'auto'}}>
                    <Card sx={{height: '100%'}} variant='outlined'>
                        <CardContent
                            style={{padding: 10}}
                        >
                            <ContainersView
                                containers={this.state.file_containers}
                                onContainerClick={async (container) => {
                                    let containers = this.state.containers;
                                    containers.push(container);
                                    containers = [...new Set(containers)];
                                    await this.setStateAsync({
                                        skip:0,
                                        containers: containers
                                    })
                                    await this.fetchFiles();
                                }}
                            />

                            {(this.state.total_files > 0 || this.state?.selected?.files?.length) ? (
                                <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{mb: 2}}>
                                    {(this.state?.selected?.files?.length > 0) ? (
                                        <Chip
                                            label={`Selected Files: ${this.state.selected.files.length} | Size: ${fData(this.state.selected.total_size)}`}
                                            onDelete={async () => {
                                                await this.setStateAsync({
                                                    selected: {
                                                        files: [],
                                                        total_size: 0
                                                    },
                                                    select_all: false,
                                                    show_selection: false,
                                                    file_ids: []
                                                });
                                            }}
                                        />

                                    ) : <Grid></Grid>}
                                    {(this.state.total_files > 0) ? (
                                        <Chip
                                            label={`Total Files: ${this.state.total_files} | Total Size: ${fData(this.state.total_size)}`}
                                        />
                                    ) : <Grid></Grid>}
                                </Stack>
                            ) : null}

                            {/* Bulk Actions */}
                            {(this.state?.selected?.files?.length) ? (
                                <Stack direction="row" spacing={2} sx={{mb: 2}}>
                                    <Chip
                                        label="Show Selected"
                                        icon={<VisibilityIcon />}
                                        variant={this.state.file_ids?.length ? 'filled' : 'outlined'}
                                        onClick={async () => {
                                            await this.toggleShowSelectedFiles();
                                        }}
                                        color='primary'
                                        disabled={this.state.select_all}
                                    />
                                    <Chip
                                        label="Select All"
                                        icon={<DoneAllIcon />}
                                        variant={this.state.select_all ? 'filled' : 'outlined'}
                                        onClick={async () => {
                                            this.setState({
                                                select_all: !this.state.select_all
                                            });
                                        }}
                                        color='primary'
                                        disabled={this.state.file_ids?.length}
                                    />
                                    <Chip
                                        label="Add labels"
                                        icon={<AddIcon />}
                                        variant='outlined'
                                        onClick={async () => {
                                            this.setState({addLabelModal: true})
                                        }}
                                    />
                                    <Chip
                                        label="Add flags"
                                        icon={<AddIcon />}
                                        variant='outlined'
                                        onClick={async () => {
                                            this.setState({addFlagModal: true})
                                        }}
                                    />
                                </Stack>
                            ) : null}

                            <Grid container spacing={1}>
                                {this.state.files.map((file) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={file._id}>
                                            <FileCard
                                                key={file._id}
                                                file={file}
                                                showSelection={this.state.show_selection}
                                                selected={this.state.selected.files.includes(file._id) || this.state.select_all}
                                                onSelect={async (file) => {
                                                    let selected = this.state.selected;
                                                    if (selected.files.includes(file._id)) {
                                                        selected.files = selected.files.filter(f => f !== file._id);
                                                        selected.total_size -= file.size;
                                                    } else {
                                                        selected.files.push(file._id);
                                                        selected.total_size += file.size;
                                                    }
                                                    await this.setStateAsync({
                                                        selected,
                                                        show_selection: true
                                                    });
                                                }}
                                                onReload={async () => {
                                                    await this.fetchFiles();
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <Stack spacing={2} sx={{ alignItems: 'center' }}>
                                <Pagination
                                    page={(this.state.skip / this.state.limit) + 1}
                                    count={Math.ceil(this.state.total_files / this.state.limit)}
                                    shape="rounded"
                                    size="large"
                                    onChange={async (event, page) => {
                                        await this.setState({
                                            skip: (page - 1) * this.state.limit
                                        });
                                        await this.fetchFiles();
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    boundaryCount={2}
                                    style={{ margin: 20 }}
                                />
                            </Stack>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>

        {(this.state.addLabelModal) ? (
            <AddLabelOrFlagModal
                open={this.state.addLabelModal}
                mode={'label'}
                select_all={this.state.select_all}
                query={this.getQuery(true)}
                total_files={this.state.total_files}
                file_ids={this.state.selected.files}
                onClose={() => this.setState({addLabelModal: false})}
                onSave={() => {
                    this.setState({addLabelModal: false})
                    this.fetchFiles();
                }}
            />
        ) : null}
        
        {(this.state.addFlagModal) ? (
            <AddLabelOrFlagModal
                open={this.state.addFlagModal}
                mode={'flag'}
                select_all={this.state.select_all}
                query={this.getQuery(true)}
                total_files={this.state.total_files}
                file_ids={this.state.selected.files}
                onClose={() => this.setState({addFlagModal: false})}
                onSave={() => {
                    this.setState({addFlagModal: false})
                    this.fetchFiles();
                }}
            />
        ) : null}

	  </>
	);
  }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Files {...props} navigate={navigate} params={params} />;
}
