import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { Card, CardHeader, Grid, Stack } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { AppConversionRates } from 'src/sections/@dashboard/app';
import AppHorizontalBarChart from 'src/sections/@dashboard/app/AppSimpleHorizontalBarChart';
// import { getFileTypeAnalytics } from 'src/services/analytics.service';

class OrgDeptWiseAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            by_count: [],
            by_size: [],
            by_count_chart_data: {
                xAxisData: [],
                seriesData: []
            },
            by_count_pie_chart_data: {
                seriesData: []
            },
            by_size_chart_data: {
                xAxisData: [],
                seriesData: []
            },
            by_size_pie_chart_data: {
                seriesData: []
            }
        }
    }

    async componentDidMount() {
        // let file_type_analytics = await getFileTypeAnalytics();
        
        // if (file_type_analytics.error) {
        //     console.error(file_type_analytics.error);
        //     return;
        // }
        // file_type_analytics = file_type_analytics.file_type_analytics;

        // let by_count_array = file_type_analytics.by_count || [];
        // let by_size_array = file_type_analytics.by_size || [];
        
        
        let by_count_array = [
            { file_type: 'jpeg', count: 164100 },
            { file_type: 'pdf', count: 24000 },
            { file_type: 'png', count: 12600 },
            { file_type: 'mp4', count: 10200 },
            { file_type: 'pptx', count: 6000 },
            { file_type: 'docx', count: 5400 },
            { file_type: 'stl', count: 3000 },
            { file_type: 'html', count: 2100 },
            { file_type: 'sldprt', count: 2100 },
            { file_type: 'dwg', count: 2100 }
        ];
        // sort by count
        by_count_array.sort((a, b) => b.count - a.count);
        let by_size_array = [
            { file_type: 'pdf', size: 300 * 1024 * 1024 * 1024 },
            { file_type: 'mp4', size: 204 * 1024 * 1024 * 1024 },
            { file_type: 'jpeg', size: 168 * 1024 * 1024 * 1024 },
            { file_type: 'docx', size: 45 * 1024 * 1024 * 1024 },
            { file_type: 'png', size: 6.9 * 1024 * 1024 * 1024 },
            { file_type: 'stl', size: 7.5 * 1024 * 1024 * 1024 },
            { file_type: 'pptx', size: 19.2 * 1024 * 1024 * 1024 },
            { file_type: 'html', size: 3.6 * 1024 * 1024 * 1024 },
            { file_type: 'sldprt', size: 2.1 * 1024 * 1024 * 1024 },
            { file_type: 'dwg', size: 1.2 * 1024 * 1024 * 1024 }
        ];
        // sort by size
        by_size_array.sort((a, b) => b.size - a.size);

        let by_count_chart_data = {
            xAxisData: [],
            seriesData: []
        }

        let by_count_pie_chart_data = {
            seriesData: []
        }

        let by_size_chart_data = {
            xAxisData: [],
            seriesData: []
        }

        let by_size_pie_chart_data = {
            seriesData: []
        }

        for(let i = 0; i < by_count_array.length; i++) {
            let item = by_count_array[i];
            if (i >= 10) {
                break;
            }
            by_count_chart_data.xAxisData.push(item.file_type);
            by_count_chart_data.seriesData.push(item.count);
            by_count_pie_chart_data.seriesData.push({
                label: item.file_type,
                value: item.count
            });
        }

        for(let i = 0; i < by_size_array.length; i++) {
            let item = by_size_array[i];
            if (i >= 10) {
                break;
            }
            by_size_chart_data.xAxisData.push(item.file_type);
            by_size_chart_data.seriesData.push(item.size / 1024 / 1024 / 1024);
            by_size_pie_chart_data.seriesData.push({
                label: item.file_type,
                value: item.size / 1024 / 1024 / 1024
            });
        }

        this.setState({
            by_count: by_count_array,
            by_size: by_size_array,
            by_count_chart_data: by_count_chart_data,
            by_count_pie_chart_data: by_count_pie_chart_data,
            by_size_chart_data: by_size_chart_data,
            by_size_pie_chart_data: by_size_pie_chart_data
        });

    }

    render() {

        return (
            <>

                <AppHorizontalBarChart
                    title="File Count"
                    subheader="by Department"
                    chartData={[
                        { label: 'Engineering', value: 67125 },
                        { label: 'R&D', value: 53700 },
                        { label: 'Marketing', value: 43275 },
                        { label: 'HR', value: 40275 },
                        { label: 'Sales', value: 26850 },
                        { label: 'Operations', value: 22850 },
                        { label: 'Finance', value: 13425 }
                    ]}
                />

            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <OrgDeptWiseAnalytics {...props} navigate={navigate} params={params} />;
}
