import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Typography, Grid, Button, TextField, InputAdornment , IconButton} from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import { register, removeKey, removeToken, requestOtp, storeKey, storeToken, verifyOtp } from 'src/services/auth.service';
import { MuiOtpInput } from 'mui-one-time-password-input';

class VerifyEmailPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            otp_length: 6,
            verifying: true,

            // payload
            otp: '',
        }

    }

    async componentDidMount() {

        this.setState({ verifying: true });

        // TODO: Check if user is already verified


        // Request OTP
        let otpResponse = await requestOtp();
        if (otpResponse.error) {
            alert('Error: ' + otpResponse.error);
            await removeToken();
            await removeKey('username');
            this.props.navigate('/login');
            return;
        }

        this.setState({ verifying: false });
    }

    validateOtp(otp) {
        if (!otp) {
            return false;
        }

        if (otp.length !== this.state.otp_length) {
            return false;
        }

        return true;
    }

    validateForm() {
        return this.validateOtp(this.state.otp);
    }

    async handleVerifyOtp() {

        if (!this.validateForm()) {
            return;
        }

        this.setState({ verifying: true });

        let payload = {
            otp: this.state.otp
        };

        let verifyOtpRes = await verifyOtp(payload);
        if (verifyOtpRes.error) {
            console.log(verifyOtpRes.error);
            alert('Error: ' + verifyOtpRes.error);
            this.setState({ otp: '', verifying: false });
            return;
        }

        // this.props.navigate('/files');
        window.location.href = '/files';
    }

    render() {

        return (
            <>
                <Helmet>
                    <title> Verify | {config.APP_NAME} </title>
                </Helmet>

                {(this.state.verifying) ? (
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
                        <Grid item xs={12} sm={8} md={5} lg={4}>
                            <Typography variant="h4" gutterBottom style={{textAlign: 'center'}}>
                                Verifying...
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
                        <Grid item xs={12} sm={8} md={5} lg={4}>
                            
                            <Typography variant="h4" gutterBottom>
                                Verify OTP sent to your E-Mail
                            </Typography>
                            
                            <MuiOtpInput
                                value={this.state.otp}
                                onChange={(otp) => this.setState({ otp })}
                                length={this.state.otp_length}
                                style={{ width: '100%', marginBottom: '20px' }}
                            />
                            
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                onClick={this.handleVerifyOtp.bind(this)}
                                disabled={!this.validateForm()}
                                xs={12} sm={8} md={5} lg={4}
                            >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                )}
                
            </>
        )
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <VerifyEmailPage {...props} navigate={navigate} />;
}
