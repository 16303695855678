import ReactApexChart from 'react-apexcharts';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

export default function AppSimpleBarChart({ chartLabels, chartData, ...other }) {
  const chartOptions = useChart({
    labels: chartLabels,
    tooltip: {
      shared: true,
      intersect: false
    },
  });

  return (
      <ReactApexChart type="line" series={chartData} options={chartOptions} height={350} />
  );
}
