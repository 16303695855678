import axios from './axios.service';
import config from './../config';
import { getRequestHeaders } from './common.service';

const register = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/auth/register', payload);
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const login = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/auth/login', payload);
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const logout = async () => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.SERVER_BASE_URL + '/auth/logout', {}, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const requestOtp = async () => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.SERVER_BASE_URL + '/auth/request-otp', {}, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const verifyOtp = async (payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post( config.SERVER_BASE_URL + '/auth/verify-otp', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const forgotPassword = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/auth/forgot-password', payload);
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const resetPassword = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/auth/reset-password', payload);
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const storeToken = (token) => {
    localStorage.setItem('token', token);
}

const getToken = () => {
    return localStorage.getItem('token');
}

const removeToken = () => {
    localStorage.removeItem('token');
}

const storeKey = (key, value) => {
    localStorage.setItem(key, String(value));
}

const getKey = (key) => {
    return String(localStorage.getItem(key));
}

const removeKey = (key) => {
    localStorage.removeItem(key);
}

export {
    register,
    login,
    logout,
    requestOtp,
    verifyOtp,
    forgotPassword,
    resetPassword,
    storeToken,
    getToken,
    removeToken,
    storeKey,
    getKey,
    removeKey
}