import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Typography, Grid, Button, TextField, InputAdornment , IconButton, Stack, ThemeProvider } from '@mui/material';
import config from 'src/config';
import Iconify from 'src/components/iconify';
import { useNavigate } from 'react-router-dom';
import { forgotPassword, getToken, login, resetPassword, storeKey, storeToken } from 'src/services/auth.service';
import logo from 'src/assets/images/logo.png';
import { createTheme } from '@mui/material/styles'
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ff9933'
      }
    },
});

class ResetPasswordPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pristine: {
                password: true,
                confirm_password: true
            },

            show_password: false,
            show_confirm_password: false,

            // payload
            // 'token' in query param
            token: new URLSearchParams(window.location.search).get('token'),
            password: '',
            confirm_password: ''
        }

    }

    async componentDidMount() {
        
    }


    validatePassword(password, checkPristine = true) {

        if (checkPristine && this.state.pristine.password) {
            return true;
        }

        if (!password) {
            return false;
        }

        if (password.length < 8) {
            return false;
        }

        return true;
    }

    validateConfirmPassword(confirm_password, checkPristine = true) {

        if (checkPristine && this.state.pristine.confirm_password) {
            return true;
        }

        if (!confirm_password) {
            return false;
        }

        if (confirm_password !== this.state.password) {
            return false;
        }

        return true;
    }

    validateForm() {
        return this.validatePassword(this.state.password, false) && this.validateConfirmPassword(this.state.confirm_password, false);
    }


    async handleResetPassword() {
        if (!this.validateForm()) {
            return;
        }

        showLoading(true);

        let payload = {
            token: this.state.token,
            password: this.state.password,
            confirm_password: this.state.confirm_password
        };

        console.log(payload)

        let resetPasswordRes = await resetPassword(payload);
        
        showLoading(false);
        
        if (resetPasswordRes.error) {
            Swal.fire({
                title: 'Error',
                text: resetPasswordRes.error,
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        Swal.fire({
            title: 'Success',
            text: 'Password reset successful. Please login with your new password.',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.navigate('/login');
            }
        });
        
    }

    render() {

        return (
            <>
                <Helmet>
                    <title> Forgot Password | {config.APP_NAME} </title>
                </Helmet>

                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }}>
                    <Grid item xs={12} sm={8} md={5} lg={4}>


                            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                <Typography variant="h4">
                                    Reset Password for
                                </Typography>
                                <img src={logo} alt="logo" width={'100px'} height={'auto'} style={{ verticalAlign: 'middle' }} />
                            </Stack>

                            <ThemeProvider theme={theme}>

                                <TextField
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={this.state.show_password ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ show_password: !this.state.show_password });
                                                    }}
                                                    edge="end"
                                                >
                                                    <Iconify icon={this.state.show_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value,
                                            pristine: {
                                                ...this.state.pristine,
                                                password: false
                                            }
                                        });
                                    }}
                                    error={!this.validatePassword(this.state.password)}
                                    helperText={!this.validatePassword(this.state.password) ? 'Password must be at least 8 characters' : ''}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    fullWidth
                                    name="confirm_password"
                                    label="Confirm Password"
                                    type={this.state.show_confirm_password ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({ show_confirm_password: !this.state.show_confirm_password });
                                                    }}
                                                    edge="end"
                                                >
                                                    <Iconify icon={this.state.show_confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.confirm_password}
                                    onChange={(e) => {
                                        this.setState({
                                            confirm_password: e.target.value,
                                            pristine: {
                                                ...this.state.pristine,
                                                confirm_password: false
                                            }
                                        });
                                    }}
                                    error={!this.validateConfirmPassword(this.state.confirm_password)}
                                    helperText={!this.validateConfirmPassword(this.state.confirm_password) ? 'Passwords didn\'t match' : ''}
                                    sx={{ mb: 2 }}
                                />
                                
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={this.handleResetPassword.bind(this)}
                                    disabled={!this.validateForm()}
                                    style={{ color: 'white' }}
                                >
                                    Reset Password
                                </Button>
                            </ThemeProvider>

                    </Grid>
                </Grid>
            </>
        )
    }
}

export default function (props) {
    const navigate = useNavigate();

    return <ResetPasswordPage {...props} navigate={navigate} />;
}

