import axios from './axios.service';
import config from './../config';
import { getRequestHeaders } from './common.service';

const getOnedriveStatus = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/onedrive/status', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getOnedriveDetails = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/onedrive', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const connectOnedrive = async (payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/onedrive/connect', payload, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const syncOnedrive = async () => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/onedrive/sync', {}, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const unsyncOnedrive = async () => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/services/onedrive/unsync', {}, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const downloadFile = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/onedrive/download/' + fileId, {
                headers: getRequestHeaders(),
                responseType: 'blob'
            });
            return response;
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const viewFile = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/onedrive/view/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDownloadLink = async (fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/onedrive/download_link/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const updateOnedriveDetails = async (payload) => {
    try {
        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/services/onedrive', payload, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getOnedriveStatus,
    getOnedriveDetails,
    connectOnedrive,
    syncOnedrive,
    unsyncOnedrive,
    downloadFile,
    viewFile,
    getDownloadLink,
    updateOnedriveDetails
}