import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { Card, CardHeader, Grid } from '@mui/material';
import { getFlaggedAnalytics } from 'src/services/analytics.service';
import { PieChart } from '@mui/x-charts';
import AppSimpleBarChart from 'src/sections/@dashboard/app/AppSimpleBarChart';

class ProjFlaggedAnalytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            by_count: [],
            by_size: [],
            by_count_chart_data: {
                xAxisData: [],
                seriesData: []
            },
            by_count_pie_chart_data: {
                seriesData: []
            },
            by_size_chart_data: {
                xAxisData: [],
                seriesData: []
            },
            by_size_pie_chart_data: {
                seriesData: []
            }
        }
    }

    async componentDidMount() {
        // let flagged_analytics = await getFlaggedAnalytics();
        
        // if (flagged_analytics.error) {
        //     console.error(flagged_analytics.error);
        //     return;
        // }
        // flagged_analytics = flagged_analytics.flagged_analytics;

        // let by_count_array = flagged_analytics.by_count || [];
        // let by_size_array = flagged_analytics.by_size || [];
        
        let by_count_array = [
            { flags: 'archive', count: 1600 },
            { flags: 'in_house', count: 800 },
            { flags: 'complete', count: 400 },
            { flags: 'final', count: 300 },
            { flags: 'attachment', count: 200 },
            { flags: 'wip', count: 200 },
            { flags: 'temp', count: 80 }
        ];
        // sort by count
        by_count_array.sort((a, b) => b.count - a.count);   
        let by_size_array = [
            { flags: 'archive', size: 40 * 1024 * 1024 * 1024 },
            { flags: 'final', size: 28 * 1024 * 1024 * 1024 },
            { flags: 'in_house', size: 20 * 1024 * 1024 * 1024 },
            { flags: 'complete', size: 20 * 1024 * 1024 * 1024 },
            { flags: 'wip', size: 8 * 1024 * 1024 * 1024 },
            { flags: 'temp', size: 4 * 1024 * 1024 * 1024 },
            { flags: 'attachment', size: 0.4 * 1024 * 1024 * 1024 },
        ];
        // sort by size
        by_size_array.sort((a, b) => b.size - a.size);

        let by_count_chart_data = {
            xAxisData: [],
            seriesData: []
        }

        let by_count_pie_chart_data = {
            seriesData: []
        }

        let by_size_chart_data = {
            xAxisData: [],
            seriesData: []
        }

        let by_size_pie_chart_data = {
            seriesData: []
        }

        for(let i = 0; i < by_count_array.length; i++) {
            let item = by_count_array[i];
            if (i >= 10) {
                break;
            }
            by_count_chart_data.xAxisData.push(item.flags);
            by_count_chart_data.seriesData.push(item.count);
            by_count_pie_chart_data.seriesData.push({
                label: item.flags,
                value: item.count
            });
        }

        for(let i = 0; i < by_size_array.length; i++) {
            let item = by_size_array[i];
            if (i >= 10) {
                break;
            }
            by_size_chart_data.xAxisData.push(item.flags);
            by_size_chart_data.seriesData.push(item.size / 1024 / 1024 / 1024);
            by_size_pie_chart_data.seriesData.push({
                label: item.flags,
                value: item.size / 1024 / 1024 / 1024
            });
        }

        this.setState({
            by_count: by_count_array,
            by_size: by_size_array,
            by_count_chart_data: by_count_chart_data,
            by_count_pie_chart_data: by_count_pie_chart_data,
            by_size_chart_data: by_size_chart_data,
            by_size_pie_chart_data: by_size_pie_chart_data
        });

    }

    render() {

        return (
            <>
                {this.state.by_count.length ? (
                    <Card variant='outlined'>
                        <CardHeader title='Flagged ( by count )' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                <PieChart
                                    series={[
                                        {
                                            data: this.state.by_count_pie_chart_data.seriesData,
                                            innerRadius: 30,
                                            outerRadius: 100,
                                            paddingAngle: 6,
                                            cornerRadius: 5,
                                        }
                                    ]}
                                    height={350}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                                <AppSimpleBarChart
                                    chartLabels={this.state.by_count_chart_data.xAxisData}
                                    chartData={[
                                        {
                                            name: 'Count',
                                            type: 'column',
                                            fill: 'solid',
                                            data: this.state.by_count_chart_data.seriesData
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                ) : null}
                
                {this.state.by_size.length ? (
                    <Card variant='outlined'>
                        <CardHeader title='Flagged ( by size in GB )' />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                <PieChart
                                    series={[
                                        {
                                            data: this.state.by_size_pie_chart_data.seriesData,
                                            innerRadius: 30,
                                            outerRadius: 100,
                                            paddingAngle: 6,
                                            cornerRadius: 5,
                                        }
                                    ]}
                                    height={350}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                                <AppSimpleBarChart
                                    chartLabels={this.state.by_size_chart_data.xAxisData}
                                    chartData={[
                                        {
                                            name: 'Size',
                                            type: 'column',
                                            fill: 'solid',
                                            data: this.state.by_size_chart_data.seriesData.map((item) => { return item.toFixed(2); })
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                ) : null}
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <ProjFlaggedAnalytics {...props} navigate={navigate} params={params} />;
}
