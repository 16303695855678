import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

let cancelGetFilesTokenSource;
async function getFiles(query=null) {

    if (cancelGetFilesTokenSource) {
        cancelGetFilesTokenSource.cancel('Operation canceled due to new request.');
    }
    cancelGetFilesTokenSource = axios.CancelToken.source();

    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/files' + queryString, {
                headers: headers,
                cancelToken: cancelGetFilesTokenSource.token
            });
        } catch (error) {

            console.log('error', error);
            if (axios.isCancel(error)) {
                return;
            }

            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

let cancelGetFilesContainersTokenSource;
async function getFilesContainers(query=null) {
    
    if (cancelGetFilesContainersTokenSource) {
        cancelGetFilesContainersTokenSource.cancel('Operation canceled due to new request.');
    }
    cancelGetFilesContainersTokenSource = axios.CancelToken.source();

    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/files/containers' + queryString, {
                headers: headers,
                cancelToken: cancelGetFilesContainersTokenSource.token
            });
        } catch (error) {

            console.log('error', error);
            if (axios.isCancel(error)) {
                return;
            }

            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}


async function getFile(fileId) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/files/' + fileId, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateFile(fileId, payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.patch( config.SERVER_BASE_URL + '/files/' + fileId, payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

async function createFile(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/files', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function generateLabels(fileId) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/files/' + fileId + '/generate_labels', {}, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateLabelsToMultipleFiles(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/files/labels', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateFlagsToMultipleFiles(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/files/flags', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function deleteMultipleFiles(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.delete( config.SERVER_BASE_URL + '/files', {
            headers: headers,
            data: payload
        });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

export {
    getFiles,
    getFilesContainers,
    createFile,
    getFile,
    updateFile,
    generateLabels,
    updateLabelsToMultipleFiles,
    updateFlagsToMultipleFiles,
    deleteMultipleFiles
}
