import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FileTypeAnalytics from 'src/components/dashboard/FileTypeAnalytics';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import FlaggedAnalytics from 'src/components/dashboard/FlaggedAnalytics';
import ProjFileTypeAnalytics from 'src/components/dashboard/ProjFileTypeAnalytics';
import ProjFlaggedAnalytics from 'src/components/dashboard/ProjFlaggedAnalytics';
import TeamFileTypeAnalytics from 'src/components/dashboard/TeamFileTypeAnalytics';
import TeamFlaggedAnalytics from 'src/components/dashboard/TeamFlaggedAnalytics';
import DeptFlaggedAnalytics from 'src/components/dashboard/DeptFlaggedAnalytics';
import DeptFileTypeAnalytics from 'src/components/dashboard/DeptFileTypeAnalytics';
import OrgFlaggedAnalytics from 'src/components/dashboard/OrgFlaggedAnalytics';
import OrgFileTypeAnalytics from 'src/components/dashboard/OrgFileTypeAnalytics';
import OrgDeptWiseAnalytics from 'src/components/dashboard/OrgDeptWiseAnalytics';
import OrganizedAnalytics from 'src/components/dashboard/OrganizedAnalytics';
import SizeAnalytics from 'src/components/dashboard/SizeAnalytics';

class Dashboard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            value: 'mine'
        };
    }

    render() {
        return (
            <>

                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, value) => {
                            this.setState({ value });
                        }} aria-label="lab API tabs example">
                            <Tab label="Mine" value="mine" />
                            <Tab label="Project" value="proj" />
                            <Tab label="Team" value="team" />
                            <Tab label="Department" value="dept" />
                            <Tab label="Organization" value="org" />
                        </TabList>
                    </Box>
                    <TabPanel value="mine">
                        <SizeAnalytics />
                        <br />
                        <OrganizedAnalytics />
                        <br />
                        <FileTypeAnalytics />
                        <br />
                        <FlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="proj">
                        <ProjFileTypeAnalytics />
                        <br />
                        <ProjFlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="team">
                        <TeamFileTypeAnalytics />
                        <br />
                        <TeamFlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="dept">
                        <DeptFileTypeAnalytics />
                        <br />
                        <DeptFlaggedAnalytics />
                    </TabPanel>
                    <TabPanel value="org">
                        <OrgDeptWiseAnalytics />
                        <br />
                        <OrgFileTypeAnalytics />
                        <br />
                        <OrgFlaggedAnalytics />
                    </TabPanel>
                </TabContext>
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Dashboard {...props} navigate={navigate} params={params} />;
}
