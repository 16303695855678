import { Autocomplete, Button, Grid, IconButton, Input, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

class ArrayInput extends React.Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            value: this.props.value || [],
            disabledCount: this.props.disabledCount || 0
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value || [],
            });
        }
    }

    addElement = () => {

        console.log('value', this.state.value);

        let value = this.state.value || [];

        console.log('value', value);

        value.push('');
        this.setState({ value });
        this.props.onChange(value);
    }

    removeElement = (index) => {
        let value = this.state.value;
        value.splice(index, 1);
        this.setState({ value });
        this.props.onChange(value);
    }

    moveElementUp = (index) => {
        let value = this.state.value;
        if (index > 0) {
            let temp = value[index];
            value[index] = value[index - 1];
            value[index - 1] = temp;
            this.setState({ value });
            this.props.onChange(value);
        }
    }

    moveElementDown = (index) => {
        let value = this.state.value;
        if (index < value.length - 1) {
            let temp = value[index];
            value[index] = value[index + 1];
            value[index + 1] = temp;
            this.setState({ value });
            this.props.onChange(value);
        }
    }


    render() {

        console.log(this.state.value);

        return (
            <Grid item xs={12} style={this.props.style ? this.props.style : null}>

                <Typography variant="h6" gutterBottom>{this.props.title}</Typography>

                {this.state.value.map((element, index) => (
                    <Stack direction="row" spacing={2} mb={2} mt={2} key={index}>
                        {(this.props.allowOrdering) ? (
                            <>
                                <IconButton aria-label="move-down" size="small"
                                    disabled={(index === this.state.value.length - 1) || (index < this.state.disabledCount)}
                                    onClick={() => this.moveElementDown(index)}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                                <IconButton aria-label="move-up" size="small"
                                    disabled={(index === 0) || (index <= this.state.disabledCount)}
                                    onClick={() => this.moveElementUp(index)}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                            </>
                        ) : null}

                        {/* If last element */}
                        {(this.props?.showSuggestions && ((index === this.state.value.length - 1) || (this.state.value.length == 0))) ? (
                            <Autocomplete
                                disabled={index < this.state.disabledCount}
                                fullWidth                   
                                options={this.props.suggestions}
                                value={element}
                                freeSolo
                                renderInput={(params) => <TextField {...params} label={this.props.placeholderText} variant="outlined" />}
                                onChangeCapture={(e) => {
                                    let value = this.state.value;
                                    value[index] = e?.target?.value || "";
                                    this.setState({ value });
                                    this.props.onChange(value);
                                }}
                                onChange={(e, newValue) => {
                                    let value = this.state.value;
                                    value[index] = newValue || "";
                                    this.setState({ value });
                                    this.props.onChange(value);
                                }}
                            />
                        ) : (
                            <TextField
                                disabled={index < this.state.disabledCount}
                                label={this.props.placeholderText}
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={element}
                                onChange={(e) => {
                                    let value = this.state.value;
                                    value[index] = e.target.value;
                                    this.setState({ value });
                                    this.props.onChange(value);
                                }}
                            />
                        )}

                        <IconButton aria-label="delete" size="small"
                            disabled={index < this.state.disabledCount}
                            onClick={() => this.removeElement(index)}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </Stack>
                ))}

                <Button variant="outlined"
                    onClick={this.addElement}
                    size="small"
                >
                    {'+ ' + this.props.buttonText}
                </Button>
            </Grid>
        )
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <ArrayInput {...props} navigate={navigate} params={params} />;
}
