import Swal from 'sweetalert2';

const showLoading = (show=true) => {
    return new Promise((resolve, reject) => {
        if (!show) {
            Swal.close();
            resolve();
            return;
        }
        Swal.fire({
            title: 'Loading',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
            didClose: () => {
                Swal.hideLoading();
                resolve();
            }
        });
        resolve();
    });
};

export default showLoading;